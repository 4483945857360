import axios from "axios";
import Holidays from "date-holidays";
import { isDateRedDay } from "../../common/utils";

const getTimeValue = (time) => {
    return parseInt(time.split(':')[0]);
};

const formatData = (data, dayId) => {
    const selectedDay = data.find(item => JSON.parse(item.id) === dayId);
    if (selectedDay) {
        return JSON.parse(selectedDay.data)
    }
    return []
};


const getAvailableTimes = async (selectedDate, bookedTimes) => {
    const currentDay = selectedDate.getDay();
    const holidays = new Holidays('SE');
    const isRedDay = holidays.isHoliday(selectedDate);
    isDateRedDay(selectedDate)
    const currentTime = new Date().getHours(); // Get the current hour
    const baseURL = process.env.REACT_APP_ENV_API_URL;
    let timesData = sessionStorage.getItem('timesData');

    if (!timesData) {
        await axios.get(`${baseURL}/times/gettimes`).then((res) => {
            const responseData = res.data;
            sessionStorage.setItem('timesData', JSON.stringify(responseData));
            timesData = res.data;
        });
    }

    let availableTimes = [];

    availableTimes = formatData(JSON.parse(timesData), currentDay);


    const isSameDay = selectedDate.getDate() === new Date().getDate();
    const availableTimesAdjusted = availableTimes.filter((time) => {
        const isBooked = bookedTimes.includes(time);
        const hasPassed = isSameDay && getTimeValue(time) <= currentTime;
        return !isBooked && !hasPassed;
    });

    if (isRedDay && isRedDay.some(day => day.type === "public")) {
        return availableTimesAdjusted[7];
    }

    return availableTimesAdjusted;
};


export default getAvailableTimes;
