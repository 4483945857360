import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Grid,
    Toolbar,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
} from '@mui/material';
import Title from '../common/Title';
import { toast } from 'react-toastify';
import axios from 'axios';

const daysOfWeekSwedish = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Röda dagar'];
const AdminTimePage = () => {
    const baseURL = process.env.REACT_APP_ENV_API_URL;
    const [selectedTimes, setSelectedTimes] = useState({
        0: [], // Söndag
        1: [], // M
        2: [],  // T
        3: [],  // Ons
        4: [],  // Tors
        5: [], // Fre
        6: [], // Lör
        7: [] //Röda dagar
    });

    useEffect(() => {
        const fetchData = async () => {

            const response = await axios.get(`${baseURL}/times/gettimes`);
            const dataFromDynamoDB = response.data;

            // Update selectedTimes based on the data retrieved from DynamoDB
            const updatedSelectedTimes = {};
            dataFromDynamoDB.forEach((item) => {
                const id = item.id;
                const data = JSON.parse(item.data);

                // Assuming data is an array of strings like ["17:00", "18:00"]
                updatedSelectedTimes[id] = data.map((time) => parseInt(time.split(':')[0], 10));
            });

            setSelectedTimes(updatedSelectedTimes);

        };

        fetchData();
    }, []);

    const handleTimeChange = (event, day) => {
        const newTimes = event.target.value
            .sort((a, b) => a - b)
        setSelectedTimes((prevSelectedTimes) => ({
            ...prevSelectedTimes,
            [day]: newTimes,
        }));
    };

    const handleSave = async () => {
        try {
            Object.entries(selectedTimes).forEach(async ([dayIndex, dayArray]) => {
                const formattedData = dayArray.map((i) => `${i}:00`);

                const payload = {
                    id: dayIndex,
                    data: formattedData,
                };
                removeOraddBlockeDayNumber(dayIndex, formattedData.length === 0)
                await axios.post(`${baseURL}/times/savetimes`, payload);
            });

            toast.success(`Uppdaterade alla tider`);
        } catch (error) {
            toast.error(`Fel när jag försökte spara tiderna. Försök igen.`);
            console.error('Error:', error.message);
        }
    };

    const removeOraddBlockeDayNumber = async (day, isDelete) => {
        try {
            if (!isDelete) {
                await axios.post(`${baseURL}/availability/addDayNumber`, { id: day });
            } else {
                await axios.delete(`${baseURL}/availability/removedaynumber/${day}`)
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }



    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Title>Tidval för varje dag</Title>
                    </Grid>
                    {daysOfWeekSwedish.map((day, index) => (
                        <Grid item xs={12} key={day}>
                            <FormControl fullWidth>
                                <InputLabel id={`${day.toLowerCase()}-time-picker-label`}>{day}</InputLabel>
                                <Select
                                    labelId={`${day.toLowerCase()}-time-picker-label`}
                                    id={`${day.toLowerCase()}-time-picker`}
                                    value={selectedTimes[index] || ''}
                                    label={day}
                                    multiple
                                    onChange={(event) => handleTimeChange(event, index)}
                                >
                                    {[...Array(11).keys()].map((hour) => (
                                        <MenuItem key={hour + 12} value={hour + 12}>
                                            {`${hour + 12}:00`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ))}
                    <Button onClick={handleSave} variant="contained" style={{
                        width: '80%',
                        margin: '0 auto',
                        marginTop: 10
                    }}>Spara</Button>
                </Grid>
            </Container>
        </Box>
    );
};

export default AdminTimePage;
