import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const AdminProtectedRoute = ({ component: Component }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkUserAuthentication = async () => {
            try {
                const storedToken = localStorage.getItem('authToken');

                if (!storedToken) {
                    navigate('/login');
                    return;
                }

                const user = await Auth.currentAuthenticatedUser();
                if (storedToken === user.signInUserSession.accessToken.jwtToken) {
                } else {
                    navigate('/login');
                }
            } catch (error) {
                navigate('/login');
            }
        };

        checkUserAuthentication();
    }, [navigate, location]);

    return <Component />;
};

export default AdminProtectedRoute;
