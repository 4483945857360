import React from 'react';

const BookNowBtn = ({ openModal }) => {
    return (
        <button className="bg-black text-yellow-400 py-2 px-4 rounded" style={{ zIndex: 10, cursor: "pointer", }} onClick={openModal}>
            Boka nu
        </button>
    );
};

export default BookNowBtn;
