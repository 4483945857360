import React from 'react'
import Framer from '../../common/Framer'
import Carousel from '../../common/Carousel';

export default function Wrapper() {
    return (
        <div>
            <Framer />
            <div className="flex items-center justify-center" style={{ height: "50vh" }}>
                <div className="flex h-full flex-col md:flex-row xxs:px-5 sm:px-20" style={{ maxWidth: "1000px", width: "100%" }}>
                    <div className="w-full md:w-1/2 h-20 h-full">
                        <div className='flex flex-col'>
                            <h2 className="font-sans text-left text-yellow-300 text-3xl font-bold break-words" style={{ marginBottom: "20px" }}>
                                Upplev våra tjänster
                            </h2>
                            <p className="font-sans text-left text-yellow-300 text-1xl" style={{ width: "370px", zIndex: 10 }}>
                                Hoppa ombord på vår lyxiga bil för en uppfriskande grooming session som tillgodoser din unika stil. <a className='underline' href="https://www.tv4play.se/program/efter-fem/sorans-kn%C3%A4ppa-klipp-fixar-frisyren-i-en-sk%C3%A5pbil/20426688">Barberstop på TV4 - Upptäck vår spännande medverkan!</a></p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 h-20 h-full">
                        <div className='w-full h-full'>
                            <Carousel />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
