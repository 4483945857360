import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { send, init } from "@emailjs/browser";
import { toast } from "react-toastify";
import { faInstagram, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { IconButton, Typography } from '@mui/material';
import Holidays from 'date-holidays';
const { REACT_APP_ENV_EMAILJS_TEMPLATE_ID, REACT_APP_ENV_EMAILJS_PUB_KEY, REACT_APP_ENV_EMAILJS_SERVICE_ID, REACT_APP_ENV_EMAILJS_TEMPLATE_ID_REMOVE_BOOKING } = process.env;
const baseURL = process.env.REACT_APP_ENV_API_URL;


const formatData = (data) => data.map(day => ({ value: day.data, label: day.data }));

const availableTime = async function (day, timesUsed) {
    if (!day) {
        return null;
    }

    let timesData = sessionStorage.getItem('timesData');

    if (!timesData) {
        await axios.get(`${baseURL}/times/gettimes`).then((res) => {
            const responseData = res.data;
            sessionStorage.setItem('timesData', JSON.stringify(responseData));
            timesData = res.data;
        });
    }

    let availableTimes = [];
    availableTimes = formatData(JSON.parse(timesData));

    const timesAvailable = [{
        name: "Måndag",
        times: availableTimes[0].data
    },
    {
        name: "Tisdag",
        times: availableTimes[1].data
    },
    {
        name: "Onsdag",
        times: availableTimes[2].data
    },
    {
        name: "Torsdag",
        times: availableTimes[3].data
    },
    {
        name: "Fredag",
        times: availableTimes[4].data
    },
    {
        name: "Lördag",
        times: availableTimes[5].data
    },
    {
        name: "Söndag",
        times: availableTimes[6].data
    },
    ];

    const correctDate = timesAvailable.find(i => i.name === day);

    if (timesUsed && timesUsed.length > 0) {
        return correctDate.times.filter(val => !timesUsed.includes(val));
    }

    return correctDate.times;
};

const dateName = function (date, timesUsed) {
    const monthNames = [
        "Januari",
        "Februari",
        "Mars",
        "April",
        "Maj",
        "Juni",
        "Juli",
        "Augusti",
        "September",
        "Oktober",
        "November",
        "December"
    ];

    const dayNames = [
        "Söndag",
        "Måndag",
        "Tisdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lördag",
    ];
    const today = new Date(date);
    const dd = today.getDate();
    const dayname = dayNames[today.getDay()];
    const mm = monthNames[today.getMonth()];
    const yyyy = today.getFullYear();
    return {
        dayname,
        dd,
        mm,
        yyyy,
        availableTime: availableTime(dayname, timesUsed)
    };
};

function countDays(dataArray) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const swedishDayNames = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'];


    const dayCounts = {};
    daysOfWeek.forEach(day => {
        dayCounts[day] = 0;
    });

    dataArray.forEach(item => {
        const date = new Date(item.date);
        const dayIndex = date.getDay();
        const dayName = daysOfWeek[dayIndex];
        dayCounts[dayName]++;
    });

    const result = Object.keys(dayCounts).map(day => ({
        id: `${daysOfWeek.indexOf(day)}somerandomid`,
        value: dayCounts[day],
        label: swedishDayNames[daysOfWeek.indexOf(day)],
    }));

    return result;
}

function countTimes(dataArray) {
    const timeArray = ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"];
    const timeCounts = {};
    timeArray.forEach(time => {
        timeCounts[time] = 0;
    });

    dataArray.forEach(item => {
        const appointmentTime = item.time;
        if (timeArray.includes(appointmentTime)) {
            timeCounts[appointmentTime]++;
        }
    });

    const result = Object.keys(timeCounts).map(time => ({
        id: `someRandomIdFor${time}`,
        value: timeCounts[time],
        label: time,
    }));

    return result;
}

const getYYYYMMMDD = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatDate(originalDate) {
    const date = new Date(originalDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based in JavaScript Date object
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function isDateRedDay(date) {
    const holidays = new Holidays('SE');

    const isHolidayArray = holidays.isHoliday(date);
    const isHoliday = Array.isArray(isHolidayArray) && isHolidayArray.length > 0;

    return isHoliday;
}

async function getFullyBookedArray() {
    try {
        const response = await axios.get(`${baseURL}/availability/booked-dates/dates`);
        const fullyBookedArray = response.data.length > 0 ? response.data.map(date => new Date(date)) : [];
        return fullyBookedArray;
    } catch (error) {
        console.error('Error retrieving fully booked dates:', error);
        return [];
    }
}



async function getBlockedDatesArray(onlyGetFromTo = false) {
    try {
        // Fetch blocked dates directly without the useGetBlockedList hook
        const response = await axios.get(`${baseURL}/barberstop/blocked/list`);
        const blockedDates = response.data;
        const blockedDatesArray = [];

        if (blockedDates.length > 0) {
            if (onlyGetFromTo) {

                return blockedDates.filter(
                    (i) => new Date(i.to.S).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
                );
            }

            blockedDates.forEach((blockedDate) => {
                const fromDate = new Date(blockedDate.from.S);
                const toDate = new Date(blockedDate.to.S);

                const currentDate = new Date(fromDate);
                currentDate.setHours(0, 0, 0, 0);
                fromDate.setHours(0, 0, 0, 0);
                toDate.setHours(0, 0, 0, 0);

                while (currentDate <= toDate) {
                    blockedDatesArray.push(new Date(currentDate));

                    currentDate.setDate(currentDate.getDate() + 1);
                }

            });
        }
        const fullyBookedArr = await getFullyBookedArray();
        return [...blockedDatesArray, ...fullyBookedArr];
    } catch (error) {
        console.error('Error retrieving blocked dates:', error);
        return [];
    }
}

async function getAvailableDays() {
    try {
        const response = await axios.get(`${baseURL}/availability/getdaynumberarray`);
        const days = response.data;
        return days;

    } catch (error) {
        console.error('Error retrieving blocked days:', error);
        return [];
    }
}


const isMobile = () => {
    const mobileScreenWidth = 640; // Adjust this value based on your desired mobile breakpoint

    return window.innerWidth < mobileScreenWidth;
};

const sendEmail = (input) => {
    init(REACT_APP_ENV_EMAILJS_PUB_KEY)
    send(REACT_APP_ENV_EMAILJS_SERVICE_ID, REACT_APP_ENV_EMAILJS_TEMPLATE_ID, {
        date: input.date,
        time: input.time,
        name: input.name,
        phone: input.phone,
        address: input.address,
        service: input.service,
        id: input.id,
        email: input.email,
        totalCost: input.totalCost
    })
        .then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            toast.error("Något gick fel, email skickades inte")
            console.log('FAILED...', error);
        });
}

const sendAvbokadEmail = (input) => {
    init(REACT_APP_ENV_EMAILJS_PUB_KEY)
    send(REACT_APP_ENV_EMAILJS_SERVICE_ID, REACT_APP_ENV_EMAILJS_TEMPLATE_ID_REMOVE_BOOKING, input)
        .then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            toast.error("Något gick fel, email skickades inte")
            console.log('FAILED...', error);
        });
}

const deleteBookingById = async (bookingId, shouldGetAll = false) => {
    try {
        let myDate;
        await axios.post(`${baseURL}/barberstop/booking/delete`, { bookingId, shouldGetAll }).then(i => {
            const { name, date, time, email } = i.data.data;
            myDate = date;
            toast.success('Booking deleted successfully!', { autoClose: 3000 });
            sendAvbokadEmail({
                name,
                date,
                time,
                to_email: email
            });
        });
        await axios.delete(`${baseURL}/availability/booked-dates/${myDate}`)
    } catch (error) {
        console.error('Error deleting booking:', error);
    }
}



const columns = [
    { field: 'date', headerName: 'Datum', width: 130, sortable: true },
    { field: 'name', headerName: 'Namn', width: 130, sortable: true },
    { field: 'email', headerName: 'Email', width: 130, sortable: true },
    { field: 'address', headerName: 'Adress', width: 130, sortable: true },
    { field: 'phone', headerName: 'Nummer', width: 130, sortable: true },
    { field: 'service', headerName: 'Service', width: 130, sortable: true },
    { field: 'time', headerName: 'Tid', width: 90, sortable: true },
    {
        field: 'shouldPickup', headerName: 'Upphämtning', width: 100, sortable: false, renderCell: (params) => {
            const shouldPickup = params.row.shouldPickup;
            return shouldPickup ? <Typography color="green"><CheckIcon /></Typography> : <Typography color="red"><CloseIcon /></Typography>
        }
    },
    {
        field: '', headerName: '', width: 20, sortable: false, renderCell: (params) => {
            const id = params.row.id;
            return <IconButton onClick={() => deleteBookingById(id)} aria-label="delete">
                <DeleteIcon color='red' />
            </IconButton>
        }
    },
];



const fetchBookings = async (searchDate, shouldGetAll = false) => {
    try {
        const response = await axios.get(`${baseURL}/barberstop/booking/list?date=${getYYYYMMMDD(searchDate)}&shouldGetAll=${shouldGetAll}`);
        const bookings = response.data;

        return bookings;
    } catch (error) {
        console.error('Error fetching bookings:', error);
        return [];
    }
};


function getIcon(name) {
    switch (name) {
        case 'youtube':
            return faYoutube;
        case 'tiktok':
            return faTiktok;
        case 'instagram':
            return faInstagram;
        default:
            return null;
    }
}

function getMonthName(monthNumb) {
    const months = ["Jan", "Feb", "Mars", "April", "Maj", "Juni", "Juli", "Aug", "Sep", "Okt", "Nov", "Dec"]
    return months[monthNumb]
}

const sortFunc = (tmp) => tmp.sort((a, b) => {
    const dateComparison = a.date.localeCompare(b.date);

    if (dateComparison === 0) {
        const timeA = a.time.split(':');
        const timeB = b.time.split(':');

        const hourComparison = parseInt(timeA[0], 10) - parseInt(timeB[0], 10);
        if (hourComparison !== 0) {
            return hourComparison;
        }

        const minuteComparison = parseInt(timeA[1], 10) - parseInt(timeB[1], 10);
        return minuteComparison;
    }

    return dateComparison;
})


export {
    getYYYYMMMDD,
    dateName,
    availableTime,
    formatDate,
    getBlockedDatesArray,
    getAvailableDays,
    isMobile,
    sendEmail,
    sendAvbokadEmail,
    deleteBookingById,
    fetchBookings,
    getIcon,
    getMonthName,
    columns,
    sortFunc,
    countDays,
    countTimes,
    isDateRedDay
}
