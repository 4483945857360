import { Delete } from '@mui/icons-material'
import { IconButton, ImageList, ImageListItem, ImageListItemBar, } from '@mui/material'
import React from 'react'

export default function ImageGalleryv2({ images, handleDeleteImage, }) {
    return (
        <ImageList sx={{ width: 500, height: 450 }}>
            {images.map((imageUrl, index) => (
                <ImageListItem key={imageUrl}>
                    <img
                        src={`https://barberstop9ab9115104ee4c979d7fe82f66420d0e150013-staging.s3.eu-north-1.amazonaws.com/public/${imageUrl}`}
                        alt={imageUrl}
                        className="w-full h-32 object-cover rounded"
                        loading='lazy'
                    />
                    <ImageListItemBar
                        subtitle={"Ta bort bild"}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'red' }}
                                onClick={() => handleDeleteImage(imageUrl)}
                            >
                                <Delete />
                            </IconButton>
                        }
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}
