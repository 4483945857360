import React from 'react'
import Framer from '../../common/Framer'
import services from '../../common/services.json';

function Services() {
    return (
        <div style={{ marginTop: "10vh" }}>
            <Framer />
            <div className="flex items-center justify-center">
                <div className="flex h-full flex-row flex-wrap md:flex-row xxs:px-5 sm:px-20" style={{ maxWidth: "1000px", width: "100%" }}>
                    <div className="w-full">
                        <div className='flex flex-col'>
                            <h2 className="font-mono text-left text-yellow-300 text-4xl font-bold break-words underline">
                                Service & Priser
                            </h2>
                        </div>
                    </div>
                    <div className="w-full  h-full flex flex-row flex-wrap">
                        {services.map(sv =>

                            <div className='flex flex-col flex-wrap w-full' style={{ borderBottom: "1px solid #000", marginBottom: "15px", paddingBottom: "15px" }} key={sv.name}>
                                <h2 className="font-sans text-left text-yellow-300 text-2xl font-bold break-words underline" style={{ margin: "20px 0" }}>
                                    {sv.name}
                                </h2>
                                <p className='font-sans text-left text-yellow-300 text-2xl font-bold break-words' style={{ padding: "5px 0" }}>
                                    {sv.price} KR
                                </p>
                                <p className='font-sans text-left text-yellow-300 text-2xl font-bold break-words' style={{ padding: "5px 0" }}>
                                    {sv.duration} min
                                </p>
                                <p className='font-sans text-left text-yellow-300 text-2xl  break-words' style={{ padding: "5px 0" }}>
                                    {sv.description}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Services