import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { MyForm } from './form';
import sv from 'date-fns/locale/sv';
import { v4 as uuidv4 } from 'uuid';

import { fetchBookings, formatDate, isDateRedDay, sendEmail } from '../../common/utils';

import getAvailableTimes from './availabilityUtils';
import { toast } from 'react-toastify';

const baseURL = process.env.REACT_APP_ENV_API_URL;




const MyModal = ({ closeModal, open, blockedDates, availableDays }) => {
    registerLocale('sv', sv);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [bookedTimes, setBookedTimes] = useState([]);
    const [blockButton, setBlockButton] = useState(false);
    const [availableTimes, setAvailableTimes] = useState([]);
    useEffect(() => {
        Modal.setAppElement('#root');
    }, []);

    const fetchBookedTimes = async () => {
        try {
            const bookings = await fetchBookings(selectedDate);
            const getTimes = bookings ? bookings.map((booking) => booking) : [];
            setBookedTimes(getTimes);
            return getTimes
        } catch (error) {
            console.error('Error fetching bookings:', error);
            return [];
        }
    };

    useEffect(() => {
        const asyncDates = async () => {
            if (selectedDate) {
                const tmpBookedtimes = await fetchBookedTimes(selectedDate, setBookedTimes);
                let uniqueBookedTimesSet = new Set(tmpBookedtimes);

                let uniqueBookedTimes = [...uniqueBookedTimesSet];
                getAvailableTimes(selectedDate, uniqueBookedTimes).then(i => setAvailableTimes(i)).catch(e => {
                    toast.error("Vi har problem att hämta tillgängliga tider, försök igen senare.")
                });
            }
        }
        asyncDates()
    }, [selectedDate]);


    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setSelectedTime('');
    };

    const handleTimeSelect = (time) => {
        setSelectedTime(time);
    };

    const handleSubmit = async (formData) => {
        try {
            const address = formData.address === undefined || formData.address === '' ? 'Norra Allén' : formData.address;
            setBlockButton(true);
            const input = {
                id: uuidv4(),
                email: formData.email,
                phone: formData.number,
                name: formData.name,
                service: formData.service,
                time: formData.time,
                date: formatDate(formData.date),
                address: address,
                shouldPickup: formData.shouldPickup,
                approvedTerms: formData.approvedTerms,
            };

            const response = await axios.post(`${baseURL}/barberstop/booking/add`, input);

            if (response && response.data) {
                await axios.post(`${baseURL}/availability/booked-dates/check`, {
                    date: input.date,
                    isRedDay: isDateRedDay(input.date),
                });
                input.totalCost = formData.totalCost;
                toast.success('Bokningen skapades framgångsrikt, vi kommer skicka ett mejl till dig.');
                sendEmail(input);
                closeModal();
                fetchBookedTimes();
                setBlockButton(false);
            } else {
                toast.error('Misslyckades att skapa bokning. Försök igen.'); // Display error notification
                setBlockButton(false);
            }

        } catch (error) {
            toast.error('Misslyckades att skapa bokning. Försök igen.'); // Display error notification
            setBlockButton(false);
        }
    };



    return (
        <Modal
            isOpen={open}
            onRequestClose={closeModal}
            style={{
                overlay: { zIndex: 1000 },
                content: { zIndex: 1001, backgroundColor: 'white' },
            }}
            className="modal fixed inset-0 flex items-center justify-center overflow-auto"
            overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 z-50"
            contentLabel="Modal"
        >
            <div className="modal-content p-4">
                <button
                    className="absolute top-4 left-4 bg-red-500 text-white rounded-full p-2"
                    onClick={closeModal}
                >
                    X
                </button>
                <h2 className="text-2xl font-bold mb-4">Välj ett datum</h2>
                <div className="relative mb-4">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-gray-500" style={{ zIndex: 10 }} />
                    </div>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateSelect}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        excludeDates={blockedDates}
                        className="pl-8 pr-3 py-2 text-sm w-full bg-white border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        placeholderText="Datum"
                        locale="sv"
                        weekStartsOn={1}
                        filterDate={(date) => {
                            const day = date.getDay();
                            return availableDays.includes(day);
                        }}
                    />
                </div>
                {selectedDate && <MyForm
                    selectedTime={selectedTime}
                    handleTimeSelect={(e) => handleTimeSelect(e)}
                    availableTimes={availableTimes}
                    handleSubmit={handleSubmit}
                    selectedDate={selectedDate}
                    blockButton={blockButton}
                />

                }
            </div>
        </Modal>
    );
};

export default MyModal;
