import * as React from 'react';
import Link from '@mui/material/Link';
import Title from '../common/Title';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { columns } from '../../../common/utils';


export default function Orders({ bookings }) {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Title>Dagens bokningar</Title>
            <DataGrid
                rows={bookings}
                columns={columns}
                loading={!bookings}
                hideFooterPagination={true}
            />
            <Link color="primary" href="#" onClick={(e) => {
                e.preventDefault()
                navigate("/soran/orders")
            }} sx={{ mt: 3 }}>
                Se alla
            </Link>
        </React.Fragment>
    );
}