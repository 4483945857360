import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing metadata

import Framer from '../../common/Framer';

export default function Welcome() {
    return (
        <div>
            <Helmet>
                <title>Välkommen till framtiden - Mobil frisörtjänst i Karlstad</title>
                <meta name="description" content="Trött på att vänta i frisersalonger? Upplev förstklassiga mobila hårklippningar och stilar hos oss." />
            </Helmet>

            <div style={{ height: "50vh" }}>
                <Framer />
                <div className="flex items-center justify-center">
                    <div className="flex flex-col md:flex-row xxs:px-5 sm:px-20" style={{ maxWidth: "1000px", width: "100%" }}>
                        <div className="w-full md:w-1/2 h-20">
                            <h2 className="font-mono text-left text-yellow-300 text-4xl font-bold break-words">
                                Välkommen till framtiden
                            </h2>
                        </div>
                        <div className="w-full md:w-1/2 h-20">
                            <h3 className="font-sans leading-2 tracking-tighter text-base text-white font-normal" style={{ fontSize: "1.5rem", lineHeight: "2rem" }}>
                                Trött på att vänta i fullsatta frisersalonger? Våra mobila frisörtjänster förvandlar din skönhetsupplevelse genom att ge dig förstklassiga hårklippningar och stilar!
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
