import { ImageList, ImageListItem } from '@mui/material';
import React from 'react';

const ImageUploader = ({ selectedImages, handleImageSelection, handleImageUpload }) => {
    return (
        <div className="bg-white p-8 rounded shadow-lg md:col-span-1">
            <h2 className="text-xl font-bold mb-2">Ladda upp bilder</h2>
            <input
                type="file"
                multiple
                onChange={handleImageSelection}
                className="border border-gray-300 p-2 mb-4"
            />

            <ImageList sx={{ width: 500, height: 450 }}>
                {selectedImages.map((image, index) => (
                    <ImageListItem key={image.preview}>
                        <img
                            src={image.preview}
                            alt={index + 'sd'}
                            className="w-full h-32 object-cover rounded"
                            loading='lazy'
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <button
                className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                onClick={handleImageUpload}
            >
                Ladda upp bilder
            </button>
        </div>
    );
};

export default ImageUploader;
