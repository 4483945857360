import { Box, Container, Grid, Paper, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Title from '../common/Title'
import { PieChart } from '@mui/x-charts/PieChart';
import { countDays, countTimes, fetchBookings, getYYYYMMMDD } from '../../../common/utils';
import Chart from '../common/Chart';

export default function DetailStats() {
    const [bookings, setBookings] = useState([]);
    const [allBookings, setAllBookings] = useState([]);

    const asyncBookings = async () => {
        const tmp = new Date();
        const today = getYYYYMMMDD(new Date(tmp.getFullYear(), 0, 1));
        const tmpBooking = await fetchBookings(today, true);
        setBookings(tmpBooking);
    };
    const asyncAllBookings = async () => {
        const tmp = new Date();
        const today = getYYYYMMMDD(new Date(tmp.getFullYear(), 0, 1));
        const tmpBooking = await fetchBookings(today, true);
        setAllBookings(tmpBooking);
    };

    useEffect(() => {
        asyncBookings();
        asyncAllBookings();
    }, []);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Title>Statestik</Title>

                            <Grid item xs={4}>
                                <Typography>Dagar med mest bokningar</Typography>
                                {bookings && <PieChart
                                    series={[
                                        {
                                            data: countDays(bookings)
                                        },
                                    ]}
                                    width={400}
                                    height={200}
                                />}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Tider med mest bokningar</Typography>
                                {bookings && <PieChart
                                    series={[
                                        {
                                            data: countTimes(bookings)
                                        },
                                    ]}
                                    width={400}
                                    height={200}
                                />}
                            </Grid>
                            <Grid item xs={12}>
                                {allBookings && <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Chart bookings={allBookings} />
                                </Paper>}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
