import React from 'react';

const HeroText = () => {
    return (
        <>
            <h1 style={{ margin: "40px 0", color: 'rgb(215, 232, 186)' }} className="font-bold text-6xl leading-tight text-left font-orelega my-4">Barberstop</h1>
        </>
    );
};

export default HeroText;
