import { Box, Container, Grid, Paper, Toolbar } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import Chart from '../common/Chart'
import Deposits from '../common/Deposits'
import Orders from '../orders/Orders'
import { fetchBookings, getYYYYMMMDD, sortFunc } from '../../../common/utils'

export default function DashboardHome() {
    const [bookings, setBookings] = useState([]);
    const [allBookings, setAllBookings] = useState([]);
    const [bookingsLastYear, setBookingsLastYear] = useState([]);
    const hasFetched = useRef(false);

    const asyncFetchAndFilterBookings = async () => {
        const tmp = new Date();
        const startOfLastYear = getYYYYMMMDD(new Date(tmp.getFullYear() - 1, 0, 1));

        const allFetchedBookings = await fetchBookings(getYYYYMMMDD(startOfLastYear), true);

        const sortedBookings = allFetchedBookings.filter(booking => {
            const bookingDate = new Date(booking.date)
            const currYear = new Date().getFullYear();
            if (parseInt(currYear) === parseInt(bookingDate.getFullYear())) {
                return booking;
            }
        })

        const allBookings = allFetchedBookings.filter(booking => {
            const bookingDate = booking.date;
            const bookingTime = booking.time;

            const currDate = new Date();
            const currDateYYYYMMMDD = getYYYYMMMDD(currDate);

            const [bookingHour] = bookingTime.split(':').map(Number);

            const currHour = currDate.getHours();

            if (currDateYYYYMMMDD === bookingDate) {
                if (bookingHour > currHour || (bookingHour === currHour)) {
                    return booking;
                }
            }
        });


        setBookings(sortFunc(allBookings));
        setAllBookings(sortedBookings);
        setBookingsLastYear(allFetchedBookings);
    };

    useEffect(() => {
        if (!hasFetched.current) {
            asyncFetchAndFilterBookings();
            hasFetched.current = true;
        }
    }, []);


    function countThisYearBooking() {
        let counter = {
            thisYear: 0,
            lastYear: 0
        };
        allBookings.forEach((booking) => {
            const bookingDate = new Date(booking.date)
            const currYear = new Date().getFullYear();
            if (parseInt(currYear) === parseInt(bookingDate.getFullYear())) {
                counter.thisYear++;
            }
        });
        bookingsLastYear.forEach((booking) => {
            const bookingDate = new Date(booking.date)
            const lastYear = new Date().getFullYear() - 1;
            if (parseInt(lastYear) === parseInt(bookingDate.getFullYear())) {
                counter.lastYear++;
            }
        });
        return counter;
    }
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Chart */}
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <Chart bookings={allBookings} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <Deposits count={countThisYearBooking()} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Orders bookings={bookings} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
