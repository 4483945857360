import { Box, Button, Container, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getBlockedDatesArray } from '../../../common/utils';
import { toast } from 'react-toastify';
import Title from '../common/Title';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';

export default function Vacation() {
    const baseURL = process.env.REACT_APP_ENV_API_URL;
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [blockedDates, setBlockedDates] = useState([]);

    const handleDeleteBlockedDate = async (blockedDateId) => {
        try {

            await axios.post(`${baseURL}/barberstop/blocked/delete`, { blockedDateId });
            const tmp = await getBlockedDatesArray(true);
            toast.success("Semester borttagen")
            setBlockedDates(tmp);

            console.log('Blocked date deleted successfully.');
        } catch (error) {
            // If there's an error, revert the change by fetching the blocked dates again
            console.error('Error deleting blocked date:', error);
            asyncBlockedDates();
        }
    };

    const handleSetBlockedDates = async () => {
        try {
            // Add blocked dates directly without the useAddBlockedDate hook
            const response = await axios.post(`${baseURL}/barberstop/blocked/add`, { from: fromDate, to: toDate, id: uuidv4() });

            // Check the response if needed
            if (response.status === 201) {
                // Clear the fromDate and toDate states
                setFromDate('');
                setToDate('');

                // Show a success message
                toast.success('Datum tillagda', { autoClose: 3000 });

                // Trigger the function to fetch asyncBlockedDates
                asyncBlockedDates();
            } else {
                // Handle any potential error response here
                console.error('Error setting blocked dates:', response.data);
            }
        } catch (error) {
            console.error('Error setting blocked dates:', error);
        }
    };

    const asyncBlockedDates = async () => {
        const tmp = await getBlockedDatesArray(true);
        setBlockedDates(tmp);
    };

    useEffect(() => {
        asyncBlockedDates();
    }, []);

    const getFormForAddingNewDate = () => <Box display={'flex'} m={4}>
        <Box mr={2}>
            <TextField
                id="fromDate"
                label="Från datum"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        </Box>
        <Box mr={2}>
            <TextField
                id="toDate"
                label="Till datum"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="outlined"
            />
        </Box>
        <Button
            variant="contained"
            color="primary"
            mt={2}
            onClick={handleSetBlockedDates}
        >
            Spara
        </Button>
    </Box>

    const getMyTable = () => <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
            <TableHead>
                <TableRow>
                    <TableCell>Från (Datum)</TableCell>
                    <TableCell>Till (Datum)</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {blockedDates.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                            {row.from.S}
                        </TableCell>
                        <TableCell>{row.to.S}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => handleDeleteBlockedDate(row.id.S)} aria-label="delete">
                                <DeleteIcon color='red' />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Title>Semesterdagar / Blockerade datum</Title>
                            {getFormForAddingNewDate()}
                            {blockedDates && getMyTable()}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
