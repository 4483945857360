import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Main from '../main/main';
import { deleteBookingById } from '../../common/utils';


function Avbokning() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookingId = searchParams.get('id');

    useEffect(() => {
        if (bookingId) {
            deleteBookingById(bookingId);
        }
    }, [bookingId]);


    return <Main />;
}

export default Avbokning;
