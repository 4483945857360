import React, { useState, useEffect, useMemo } from 'react';
import { Storage } from 'aws-amplify';

const Carousel = () => {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imageKeys = await Storage.list('bilder/', { level: 'public', pageSize: 1000 });
                const imageUrls = imageKeys.results.filter((imageKey) => imageKey.key.match(/\.(jpg|png)$/i));
                setImages(imageUrls);
            } catch (error) {
                console.error('Error fetching images', error);
            }
        };

        fetchImages();
    }, []);

    const cachedImageUrls = useMemo(() => {
        return images.map((imageKey, index) => ({
            src: `https://barberstop9ab9115104ee4c979d7fe82f66420d0e150013-staging.s3.eu-north-1.amazonaws.com/public/${imageKey.key}`,
            alt: `Image ${index + 1}`,
        }));
    }, [images]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % cachedImageUrls.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [cachedImageUrls]);

    return (
        <div className="relative w-full h-64 md:h-96">
            <div className="absolute top-0 left-0 w-full h-full">
                {cachedImageUrls.map((imageUrl, index) => (
                    <img
                        key={index}
                        src={imageUrl.src}
                        alt={imageUrl.alt}
                        className={`absolute top-0 left-0 ${index === currentIndex ? 'opacity-100' : 'opacity-0'
                            }`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            transition: 'opacity 1s ease-in-out',
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;
