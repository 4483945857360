import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { Skeleton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';


export default function Deposits({ count }) {

    function getPercentage() {
        const percentageChange = ((count.thisYear - count.lastYear) / Math.abs(count.lastYear)) * 100;
        let change = {
            color: '',
            text: '',
            icon: ''
        }
        if (percentageChange > 0) {
            change = {
                color: '#014421',
                text: `+${percentageChange.toFixed(2)}%`,
                icon: <ArrowUpward />
            }
        } else if (percentageChange < 0) {
            change = {
                color: '#FF0000',
                text: `${percentageChange.toFixed(2)}%`,
                icon: <ArrowDownward />
            }
        } else {
            change = {
                color: '#000',
                text: `00.00%`,
                icon: <></>
            }
        }

        return <Typography color={change.color}>{change.text}{change.icon}</Typography>
    }

    return (
        <React.Fragment>
            <Title>Total bokningar {new Date().getFullYear()}</Title>
            {count.thisYear ? (<Typography component="p" variant="h4">
                {count.thisYear} st
            </Typography>) : (<Skeleton variant="rectangular" width={40} />)}
            <Title>Total bokningar {new Date().getFullYear() - 1}</Title>
            {count.lastYear ? (<Typography component="p" variant="h4">
                {count.lastYear} st
            </Typography>) : (<Skeleton variant="rectangular" width={40} />)}
            {count.lastYear && count.lastYear ? getPercentage() : (<Skeleton variant="rectangular" width={40} />)}
        </React.Fragment>
    );
}