import { Box, Container, Grid, Paper, Toolbar } from '@mui/material'
import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import Title from '../common/Title';
import { toast } from 'react-toastify';
import ImageGalleryv2 from './ImageGalleryv2';
import ImageUploader from './ImageUploader';


export default function Images() {
    // State variables for the image gallery and upload
    const [images, setImages] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);

    const fetchImages = async () => {
        try {
            const imageKeys = await Storage.list('bilder/', { level: 'public', pageSize: 1000 });
            const imageUrls = imageKeys.results
                .filter((imageKey) => imageKey.key.match(/\.(jpg|png)$/i))
                .map((imageKey) => imageKey.key);
            setImages(imageUrls);
            setLoadingImages(false);
        } catch (error) {
            console.error('Error fetching images', error);
            setLoadingImages(false);
        }
    };

    const handleDeleteImage = async (imageUrl) => {
        // Implement the logic to delete an image from AWS S3
        try {
            await Storage.remove(imageUrl, { level: 'public' });
            const filteredImages = images.filter((image) => image !== imageUrl);
            toast.error('Bild borttagen');
            setImages(filteredImages);
        } catch (error) {
            console.error('Error deleting image', error);
        }
    };

    const handleImageUpload = async () => {
        // Implement the logic to upload selected images to AWS S3
        try {
            for (const image of selectedImages) {
                await Storage.put(`bilder/${image.name}`, image.file, { level: 'public' });
            }
            // Refresh the images after upload
            toast.success('Bild uppladdad');
            setLoadingImages(true);
            fetchImages();
            setSelectedImages([]);
        } catch (error) {
            console.error('Error uploading images', error);
        }
    };

    const handleImageSelection = (e) => {
        // Handle the image selection from the input file
        const files = e.target.files;
        const selected = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onloadend = () => {
                selected.push({ name: file.name, file: file, preview: reader.result });
                if (selected.length === files.length) {
                    setSelectedImages(selected);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        fetchImages();

        // Interval to refresh the images every 5 seconds
        const interval = setInterval(() => {
            fetchImages();
        }, 5000);

        return () => clearInterval(interval);
    }, []);



    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Title>Bilder</Title>
                            {!loadingImages && <ImageGalleryv2 images={images} handleDeleteImage={handleDeleteImage} />}
                            <ImageUploader selectedImages={selectedImages} handleImageSelection={handleImageSelection} handleImageUpload={handleImageUpload} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
