/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_mobile_analytics_app_id": "dd5eb9fac39540bd9e2108e639e4154a",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "dd5eb9fac39540bd9e2108e639e4154a",
            "region": "eu-central-1"
        }
    },
    "Notifications": {
        "Email": {
            "AWSPinpoint": {
                "appId": "dd5eb9fac39540bd9e2108e639e4154a",
                "region": "eu-central-1"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://vw5m2f2w7zcyzhgql7mvgwbbhm.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hg5pkqvy7fg53lp2hwg7sb6564",
    "aws_cognito_identity_pool_id": "eu-north-1:3b9161c7-5ff5-4947-ba74-03480f502b7b",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_xNRLRDahV",
    "aws_user_pools_web_client_id": "761boehsuna2j39063p6d0uidt",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "barberstop9ab9115104ee4c979d7fe82f66420d0e150013-staging",
    "aws_user_files_s3_bucket_region": "eu-north-1"
};


export default awsmobile;
