import React from 'react';

const Framer = ({ disableHeight }) => {
    const framerContainerClasses = `flex flex-row w-full ${disableHeight ? '' : 'h-full'} items-center justify-between content-center bottom-0 overflow-hidden p-0 z-10 h-full`;
    const framerClasses = ` ${disableHeight ? '' : 'h-full'} absolute top-0 bottom-0 left-0 right-0 flex items-center`;
    const LineComponent = () => {
        return <div className="flex-shrink-0 bg-white opacity-10 h-full" style={{ width: '1px' }}></div>;
    };
    const lineComponents = Array(12).fill(<LineComponent />);

    return (
        <div className="absolute w-2200px h-full overflow-hidden w-full">
            <div className={framerClasses}>
                <div className={framerContainerClasses} style={{ disableHeight }}>
                    {lineComponents.map((line, index) => (
                        <React.Fragment key={index}>{line}</React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Framer;