import React from 'react'
import socialMedia from '../../common/socialmedia.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIcon } from '../../common/utils';

export default function Footer() {
    return (
        <div style={{ margin: "50px 0", height: "100px" }}>
            <div className="flex items-center justify-center">
                <div className="flex h-full flex-row flex-wrap md:flex-row xxs:px-5 sm:px-20" style={{ maxWidth: "1000px", width: "100%" }}>
                    <div className="w-full">
                        <div className='flex flex-row'>
                            <footer className="footer">
                                <div className="flex">
                                    {socialMedia?.map((item) => (
                                        <a href={item.url} target="_blank" rel="noopener noreferrer" key={item.platform}>
                                            <FontAwesomeIcon icon={getIcon(item.platform)} className="mr-2 text-base text-white transition duration-300 hover:text-white" style={{ height: "30px", width: "30px" }} />
                                        </a>
                                    ))}
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
