import React, { useState } from 'react';
import services from '../../common/services.json';


export const MyForm = ({ selectedTime, handleTimeSelect, availableTimes, handleSubmit, selectedDate, blockButton }) => {
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [service, setService] = useState('');
    const [shouldPickup, setShouldPickup] = useState(false);
    const [errors, setErrors] = useState({});
    const [price, setPrice] = useState(0)

    const setupFormObj = (e) => {
        const formData = new FormData(e.target);
        formData.append("email", email);
        formData.append("number", number);
        formData.append("name", name);
        formData.append("service", service);
        formData.append("time", selectedTime);
        formData.append("date", selectedDate);
        formData.append("approvedTerms", true);
        formData.append("shouldPickup", shouldPickup);
        formData.append("totalCost", price);
        formData.append('address', shouldPickup ? address : 'Sundstagymnasiets Parkering');

        return Object.fromEntries(formData.entries());
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setErrors({});

        const validationErrors = {};

        if (selectedTime.trim() === '' || selectedTime.trim() === "Välj en tid") {
            validationErrors.selectedTime = 'Du måste välja en tid';
        }

        if (name.trim() === '') {
            validationErrors.name = 'Vänligen ange ditt namn';
        }

        if (service.trim() === '' || service.trim() === "Välj en tjänst") {
            validationErrors.service = 'Vänligen välj en tjänst';
        }

        if (email.trim() === '' || !email.includes('@') || !email.includes('.')) {
            validationErrors.email = 'Vänligen ange en giltlig email';
        }

        if (number.trim() === '' || !number.startsWith('07')) {
            validationErrors.number = 'Vänligen ange ett giltigt nummer som börjar med 07';
        }

        if (shouldPickup && address.trim() === '') {
            validationErrors.address = 'Vänligen ange din adress';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formDataObject = setupFormObj(e)
        handleSubmit(formDataObject);
    };

    const myLabel = (id, txt) => <label htmlFor={id} className="block mb-2 text-sm font-medium text-black" style={{ color: "#000!important" }}>
        {txt}
    </label>

    const getPrice = () => shouldPickup ? price + 50 : price;

    return (
        <form onSubmit={handleFormSubmit}>
            <div>
                {myLabel("available_times", "Välj en tid")}
                <select
                    id="available_times"
                    value={selectedTime}
                    onChange={(e) => handleTimeSelect(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                >
                    <option label="Välj en tid">Välj en tid</option>
                    {availableTimes && availableTimes.map(serv => <option label={serv} key={serv}>{serv}</option>)}
                </select>
                {errors.selectedTime && (
                    <p className="text-red-500 text-sm">{errors.selectedTime}</p>
                )}
            </div>

            <div className='my-4'>
                {myLabel("my_name", "Namn")}
                <input
                    type="text"
                    id="my_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                />
                {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name}</p>
                )}
            </div>

            <div className='my-4'>
                {myLabel("my_email", "Email")}
                <input
                    type="email"
                    id="my_email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                />
                {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                )}
            </div>

            <div className='my-4'>
                {myLabel("my_number", "Nummer")}
                <input
                    type="tel"
                    id="my_number"
                    pattern="07[0-9]{8}"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                />
                {errors.number && (
                    <p className="text-red-500 text-sm">{errors.number}</p>
                )}
            </div>

            <div className='my-4'>
                {myLabel("my_srvice", "Tjänst")}
                <select
                    id="my_srvice"
                    value={service}
                    onChange={(e) => {
                        setService(e.target.value)
                        if (e.target.value !== "Välj en tjänst") {
                            const tmpPrice = services.find((service) => service.name === e.target.value).price;
                            setPrice(tmpPrice)
                        } else {
                            setPrice(0)
                        }
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                >
                    <option label="Välj en tjänst">Välj en tjänst</option>
                    {services
                        .filter(serv => serv.name !== 'Åka till er')
                        .map(serv => (
                            <option label={serv.name} key={serv.name}>
                                {serv.name}
                            </option>
                        ))}

                </select>
                {errors.service && (
                    <p className="text-red-500 text-sm">{errors.service}</p>
                )}
            </div>

            <div className='my-4'>
                <input
                    type="checkbox"
                    id="shouldPickup"
                    checked={shouldPickup}
                    onChange={(e) => setShouldPickup(e.target.checked)}
                    className="mr-2"
                />
                <label htmlFor="shouldPickup" className="text-sm font-medium text-gray-900" style={{ color: "#000!important" }}>
                    Vill du ha hemkörning? (Karlstad)
                </label>
            </div>


            {shouldPickup && <div>
                <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 my-4">
                    Address (Karlstad)
                </label>
                <input
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required={shouldPickup}
                    disabled={!shouldPickup}
                />
                {errors.address && (
                    <p className="text-red-500 text-sm">{errors.address}</p>
                )}
            </div>}
            <p className='text-green-600'>Totalkostnad: {getPrice()} kr</p>
            <button
                type="submit"
                className={`bg-black text-yellow-400 py-2 px-4 rounded block my-4 ${blockButton ? 'disabled' : ''}`}
                disabled={blockButton}
                style={{ opacity: blockButton ? 0.5 : 1, cursor: blockButton ? 'not-allowed' : 'pointer', position: 'relative' }}
            >
                {blockButton ? (
                    <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="ml-2">Lägger till bokning...</span>
                    </>
                ) : (
                    'Boka tid'
                )}
            </button>
        </form>
    );
};
