import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';

import Title from './Title';
import { getMonthName } from '../../../common/utils';

export default function Chart({ bookings }) {
    const theme = useTheme();

    const setupData = () => {
        if (!bookings || bookings.length === 0) {
            return []
        }
        return countItemsPerMonth()
    }

    function countItemsPerMonth() {
        const months = ["Jan", "Feb", "Mars", "April", "Maj", "Juni", "Juli", "Aug", "Sep", "Okt", "Nov", "Dec"]
        const counts = {};

        bookings.forEach((booking) => {
            const bookingDate = new Date(booking.date)
            const year = new Date().getFullYear();
            if (parseInt(year) === parseInt(bookingDate.getFullYear())) {
                const month = getMonthName(bookingDate.getMonth())
                if (counts[month]) {
                    counts[month]++;
                } else {
                    counts[month] = 1;
                }
            }
        });
        const result = months.map((monthName) => ({ month: monthName, count: counts[monthName] || null }))
        return result;
    }


    return (
        <React.Fragment>
            <Title>Bokningar {new Date().getFullYear}</Title>
            {setupData().length > 0 && <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
                <LineChart
                    dataset={setupData()}
                    margin={{
                        top: 16,
                        right: 20,
                        left: 70,
                        bottom: 30,
                    }}
                    xAxis={[
                        {
                            scaleType: 'point',
                            dataKey: 'month',
                            tickNumber: 2,
                            tickLabelStyle: theme.typography.body2,
                        },
                    ]}
                    yAxis={[
                        {
                            label: 'Antal',
                            labelStyle: {
                                ...theme.typography.body1,
                                fill: theme.palette.text.primary,
                            },
                            tickLabelStyle: theme.typography.body2,
                            max: 2500,
                            tickNumber: 3,
                        },
                    ]}
                    series={[
                        {
                            dataKey: 'count',
                            showMark: false,
                            color: theme.palette.primary.light,
                        },
                    ]}
                    sx={{
                        [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
                        [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
                        [`& .${axisClasses.left} .${axisClasses.label}`]: {
                            transform: 'translateX(-25px)',
                        },
                    }}
                />
            </div>}
        </React.Fragment>
    );
}