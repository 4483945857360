import React from 'react'
import { useState } from 'react';
import { getAvailableDays, getBlockedDatesArray } from '../../common/utils';
import { useEffect } from 'react';
import { Footer, Hero, MyModal, Services, Welcome, Wrapper } from '../index';

export default function Main() {
    const [open, setOpen] = useState(false);
    const [blockedDates, setBlockedDates] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);

    const fetchBlockedDates = async () => {
        const data = await getBlockedDatesArray();
        setBlockedDates(data);
    }

    const fetchAvailableDays = async () => {
        const data = await getAvailableDays();
        setAvailableDays(data);
    }

    useEffect(() => {
        fetchBlockedDates();
        fetchAvailableDays();
    }, [])

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <Hero openModal={handleOpenModal} />
            <Welcome />
            <Wrapper />
            <Services />
            <Footer />
            <MyModal closeModal={handleCloseModal} open={open} blockedDates={blockedDates} availableDays={availableDays} />
        </>
    )
}
