import React, { useEffect, useState } from 'react'
import { columns, fetchBookings, getYYYYMMMDD, sortFunc } from '../../../common/utils'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import Title from '../common/Title';


export default function OrderPage() {
    const [bookings, setBookings] = useState([]);
    const asyncBookings = async () => {
        const today = getYYYYMMMDD(new Date());
        const tmpBooking = await fetchBookings(today, true);
        setBookings(sortFunc(tmpBooking));
    };

    useEffect(() => {
        asyncBookings();
    }, []);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <Title>Alla bokningarna</Title>

                            <DataGrid
                                rows={bookings}
                                columns={columns}
                                loading={!bookings}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
