import './App.css';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import Main from './pages/main/main';
import Avbokning from './pages/avbokning/Avbokning';
import AdminProtectedRoute from './pages/admin/AdminProtectedRoute';
import Login from './pages/login/login';
import Dashboard from './pages/admin/Dashboard';
import DashboardHome from './pages/admin/home/DashboardHome';
import OrderPage from './pages/admin/orders/OrderPage';
import Vacation from './pages/admin/vacation/Vacation';
import Images from './pages/admin/images/Images';
import DetailStats from './pages/admin/stats/DetailStats';
import AdminTimePage from './pages/admin/times/AdminTimePage';
import { useEffect } from 'react';
import axios from 'axios';

function App() {
  useEffect(() => {
    const baseURL = process.env.REACT_APP_ENV_API_URL;
    const timesData = sessionStorage.getItem('timesData');
    if (!timesData) {
      axios.get(`${baseURL}/times/gettimes`).then((res) => {
        const responseData = res.data;
        sessionStorage.setItem('timesData', JSON.stringify(responseData));
      });
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="soran" element={<AdminProtectedRoute component={Dashboard} />}>
          <Route index element={<AdminProtectedRoute component={DashboardHome} />} />
          <Route path="orders" element={<AdminProtectedRoute component={OrderPage} />} />
          <Route path="stats" element={<AdminProtectedRoute component={DetailStats} />} />
          <Route path="vacation" element={<AdminProtectedRoute component={Vacation} />} />
          <Route path="images" element={<AdminProtectedRoute component={Images} />} />
          <Route path="availabletimes" element={<AdminProtectedRoute component={AdminTimePage} />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="avboka" element={<Avbokning />} />
        <Route path="*" element={<Main />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
