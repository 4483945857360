import React from 'react';
import backgroundImage from '../../media/heroimgsmall.jpg';

const HeroImage = () => {
    const secondImageStyle = {
        left: '63px',
        position: 'absolute',
        top: '-1px',
        zIndex: '-99'
    };

    return (
        <>
            <div
                className="bg-cover h-129 w-129  rounded-full bg-cover bg-center bg-no-repeat"
                style={{ background: `url('${backgroundImage}')`, width: 129, height: 129, backgroundSize: 'cover', backgroundPosition: 'center' }}
            ></div>

            <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" className="bg-cover h-129 w-129  rounded-full"
                style={{ ...secondImageStyle }}>
                <path d="M 64.5 0.713 C 99.729 0.713 128.287 29.271 128.287 64.5 C 128.287 64.545 128.287 64.591 128.287 64.636 L 0.713 64.636 C 0.713 64.591 0.713 64.545 0.713 64.5 C 0.713 29.271 29.271 0.713 64.5 0.713 Z M 64.5 128.287 C 53.759 128.287 43.638 125.632 34.757 120.943 L 94.243 120.943 C 85.362 125.632 75.241 128.287 64.5 128.287 Z M 128.246 66.812 C 128.197 68.184 128.105 69.544 127.971 70.892 L 1.029 70.892 C 0.895 69.544 0.803 68.184 0.754 66.812 Z M 127.717 73.068 C 127.507 74.628 127.242 76.171 126.921 77.693 L 2.079 77.693 C 1.758 76.171 1.493 74.628 1.283 73.068 Z M 125.844 82.045 C 125.397 83.612 124.891 85.154 124.329 86.669 L 4.671 86.669 C 4.109 85.154 3.603 83.612 3.156 82.045 Z M 122.53 91.021 C 121.764 92.694 120.928 94.327 120.026 95.918 L 8.974 95.918 C 8.072 94.327 7.236 92.694 6.47 91.021 Z M 113.646 105.166 C 111.724 107.486 109.641 109.668 107.413 111.695 L 21.587 111.695 C 19.359 109.668 17.276 107.486 15.354 105.166 Z" fill="#FFFFFF"></path>
            </svg>
        </>
    );
};

export default HeroImage;
