import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { AccessAlarm, CameraAlt, EventNote, EventNoteOutlined, TrendingUp } from '@mui/icons-material';

export const mainListItems = (navigate) => {
    return (
        <React.Fragment>
            <ListItemButton onClick={() => navigate('/soran')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/soran/orders')}>
                <ListItemIcon>
                    <EventNote />
                </ListItemIcon>
                <ListItemText primary="Bokningar" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/soran/stats')}>
                <ListItemIcon>
                    <TrendingUp />
                </ListItemIcon>
                <ListItemText primary="Statestik" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/soran/vacation')}>
                <ListItemIcon>
                    <EventNoteOutlined />
                </ListItemIcon>
                <ListItemText primary="Semester" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/soran/images')}>
                <ListItemIcon>
                    <CameraAlt />
                </ListItemIcon>
                <ListItemText primary="Bilder" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/soran/availabletimes')}>
                <ListItemIcon>
                    <AccessAlarm />
                </ListItemIcon>
                <ListItemText primary="Öppettider" />
            </ListItemButton>
        </React.Fragment>
    );
}