import React from 'react';
import Framer from '../../common/Framer';
import HeroImage from './heroImage';
import HeroText from './herotext';
import BookNowBtn from '../../common/BookNowBtn';

const Hero = ({ openModal }) => {
    return (
        <header className="flex flex-col items-center justify-center h-min-content min-h-800 overflow-visible p-100 relative w-full h-screen" style={{ gap: "10px", height: "50vh" }}>
            <Framer />
            <div
                className="flex flex-col items-start justify-center xxs:px-5 sm:px-20"
                style={{ maxWidth: "1000px", width: "100%" }}
            >
                <div className='relative'>
                    <HeroImage />
                </div>
                <div className="outline-none flex flex-col justify-start flex-shrink-0 transform-none flex-none h-auto max-w-800 relative whitespace-pre-wrap w-full word-break-break-word word-wrap-break-word">
                    <HeroText />
                </div>
                <div className='outline-none flex flex-col justify-start flex-shrink-0 transform-none'>
                    <BookNowBtn openModal={openModal} />
                </div>
            </div>
        </header >
    );
};

export default Hero;
